<template>
  <div>
    <search-agent-purchase-bill @submit="submitSearch" />

    <div class="iotplt-table-operation-buttons">
      <a-button type="primary" @click="exportData">导出</a-button>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="agent_bill_id"
    >
      <div slot="item_no" slot-scope="item_no, record">
        <div v-if="isShowMenu('agent_purchase_items/show')">
          <a @click="showInfoModal(record.purchase_item_id)">{{ item_no }}</a>
        </div>
        <div v-else>
          {{ item_no }}
        </div>
      </div>
    </a-table>
    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 详情模态框 -->
    <show-agent-purchase-item
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findAgentConsumptionPurchaseBills, exportAgentConsumptionPurchaseBills } from '@/api/agent_consumption_bill'
import { hasPermission } from '@/utils/permission'
import SearchAgentPurchaseBill from '@/views/agent_consumption_bills/purchase/Search'
import { formatBigNumber, formatCurrency } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'AgentPurchaseBillList',
  components: {
    Pagination,
    SearchAgentPurchaseBill,
    ShowAgentPurchaseItem: () => import('@/views/agent_consumption_bills/purchase/Show')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowInfoModal: false,
      showingId: 0,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  computed: {
    columns() {
      return [
        { title: '项目批次', dataIndex: 'item_no', scopedSlots: { customRender: 'item_no' }, width: 235 },
        { title: '账单时间', dataIndex: 'bill_time', width: 200 },
        { title: '运营商种类', dataIndex: 'carrier_type', width: 100 },
        { title: '客户套餐', dataIndex: 'agents_product_name', width: 200 },
        { title: '周期数', dataIndex: 'service_period', customRender: formatBigNumber, width: 80 },
        { title: '采购卡数(张)', dataIndex: 'card_count', customRender: formatBigNumber, width: 100 },
        { title: '套餐价格(元)', dataIndex: 'product_price', customRender: formatCurrency, width: 100 },
        { title: '卡板价格', dataIndex: 'card_price', width: 100 },
        { title: '套餐费用(元)', dataIndex: 'product_fee', customRender: formatCurrency, width: 100 },
        { title: '卡板费用(元)', dataIndex: 'card_fee', customRender: formatCurrency, width: 100 },
        { title: '账单金额(元)', dataIndex: 'total_fee', customRender: formatCurrency, width: 100 },
        { title: '备注', dataIndex: 'remark', width: 100, ellipsis: true }
      ]
    },

    agentId() {
      if (this.$route.query.agent_id) {
        return parseInt(this.$route.query.agent_id)
      }

      return this.$store.getters.userAgentId
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showInfoModal(recordId) {
      this.showingId = recordId
      this.isShowInfoModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAgentConsumptionPurchaseBills(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },

    exportData() {
      exportAgentConsumptionPurchaseBills(Object.assign({ agent_id: this.agentId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

